import React from "react"
import backgroundValori from "../../assets/images/about_us/background_valori.jpg"
import backgroundTeam from "../../assets/images/about_us/background-team.jpeg"
import header2 from "../../assets/images/about_us/header_2.jpg"
import sez0 from "../../assets/images/about_us/sez-0.png"
import sez2 from "../../assets/images/about_us/sez-2.png"
import sez3 from "../../assets/images/about_us/sez-3.png"
import sez4 from "../../assets/images/about_us/sez-4.png"
import sez5 from "../../assets/images/about_us/sez-5.png"
import sez6 from "../../assets/images/about_us/sez-6.png"
import sez7 from "../../assets/images/about_us/sez-7.png"
import sez8 from "../../assets/images/about_us/sez-8.png"
import sez9 from "../../assets/images/about_us/sez-9.png"
import team1 from "../../assets/images/about_us/team_1.png"
import team2 from "../../assets/images/about_us/team_2.png"
import team4 from "../../assets/images/about_us/team_4.png"
import team5 from "../../assets/images/about_us/team_5.png"
import team6 from "../../assets/images/about_us/team_6.png"
import valori1 from "../../assets/images/about_us/valori_item_1.jpg"
import valori2 from "../../assets/images/about_us/valori_item_2.jpg"
import valori3 from "../../assets/images/about_us/valori_item_3.jpg"
import valori4 from "../../assets/images/about_us/valori_item_4.jpg"
import valori5 from "../../assets/images/about_us/valori_item_5.jpg"
import virgolette from "../../assets/images/about_us/virgolette.jpg"
import customCss from "./aboutUs.css?inline"
import { Helmet } from "react-helmet"

const AboutUs: React.FC = () => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
{
  "@context": "https://schema.org",
	"@graph":[
    
    
    
    {
      "@type": "WebSite",
			"@id": "https://www.eshoppingadvisor.com/#website",
			"url": "https://www.eshoppingadvisor.com/it/",
			"name": "eShoppingAdvisor",
			"publisher":{
        "@id":"https://www.eshoppingadvisor.com/#organization"
			},
			"potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "${
            import.meta.env.VITE_B2C_BASE_URL
          }it/search/eshopSearch/{search_term_string}"
        },
        "query-input": "required name=search_term_string"
      },
			"description": "Siamo un Team Italiano con la mission di rendere il web un luogo molto più sicuro nel quale acquistare. ",
			"inLanguage": "it-IT"
		},
		
				
		{
      "@type": "WebPage",
			"@id": "https://www.eshoppingadvisor.com/it/chi-siamo#webpage",
			"url": "https://www.eshoppingadvisor.com/it/chi-siamo",
			"name": "eShoppingAdvisor: chi siamo e qual è la nostra mission",
			"isPartOf": {
        "@id": "https://www.eshoppingadvisor.com/#website"
			},
			"primaryImageOfPage": {
        "@id": "https://www.eshoppingadvisor.com/#primaryimage"
			},
			"description": "Siamo un Team Italiano con la mission di rendere il web un luogo molto più sicuro nel quale acquistare. ",
			"inLanguage": "it-IT"
		}
	]
}

        `}
        </script>
      </Helmet>
      <section className="chi-siamo-page">
        <div className="info-section">
          <div className="container">
            <div id="header-1" className="row my-3 align-items-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <img
                  src={virgolette}
                  className="d-block img-fluid"
                  width="65"
                  height="47"
                  alt="\"
                  loading="lazy"
                />
                <h3 className="header-title">
                  eShoppingAdvisor è la <b> guida di riferimento </b> per uno{" "}
                  <b>shopping online sicuro e sostenibile.</b> Siamo un
                  ecosistema innovativo e affidabile che dà voce ai consumatori
                  e <b> garantisce crescita, visibilità e credibilità </b> ai
                  medi e piccoli e-commerce.
                </h3>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="header-img header-video-container">
                  <iframe
                    width="521"
                    height="271"
                    className="w-100"
                    src="https://www.youtube.com/embed/cTgRZp_Oa2c"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    style={{ border: "none", overflow: "hidden" }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div id="header-2" className="row my-3 align-items-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 order-lg-2 order-xl-2">
                <img
                  src={virgolette}
                  className="d-block img-fluid"
                  width="65"
                  height="47"
                  alt="\"
                  loading="lazy"
                />
                <h3 className="header-title">
                  La nostra vision è quella di portare{" "}
                  <b> sicurezza, sostenibilità e qualità </b> nell’ecosistema
                  del commercio on-line.
                </h3>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <img
                  src={header2}
                  className="d-block img-fluid header-img"
                  width="521"
                  height="271"
                  alt="header_2"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="info-section valori-container"
          style={{ backgroundImage: `url(${backgroundValori})` }}
        >
          <div className="container">
            <div className="row my-3 align-items-center justify-content-center align-items-stretch">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center my-3">
                <h4 className="valori-title"> I nostri valori </h4>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center my-3">
                <div className="d-flex flex-column align-items-start valori-item mx-2 p-5 h-100">
                  <div className="align-items-stretch">
                    <h3 className="valori-item-title mt-1 mb-4 mx-auto">
                      <img
                        src={valori1}
                        className="d-inline-block img-fluid"
                        width="38"
                        height="38"
                        alt="Sicurezza"
                        loading="lazy"
                      />
                      Sicurezza{" "}
                    </h3>
                    <p className="valori-item-p my-3">
                      {" "}
                      Garantire un’<b>
                        esperienza di shopping online sicura{" "}
                      </b>{" "}
                      a tutti i nostri utenti è per noi un obiettivo
                      prioritario. <br /> Grazie alle{" "}
                      <b> recensioni verificate </b> le persone possono infatti
                      consultare feedback reali in grado di orientarli verso la
                      scelta migliore, evitando possibili truffe o disservizi.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center my-3">
                <div className="d-flex flex-column align-items-start valori-item mx-2 p-5 h-100">
                  <div className="align-items-stretch">
                    <h3 className="valori-item-title mt-1 mb-4 mx-auto">
                      <img
                        src={valori2}
                        className="d-inline-block img-fluid"
                        width="38"
                        height="38"
                        alt="Sostenibilità"
                        loading="lazy"
                      />
                      Sostenibilità{" "}
                    </h3>
                    <p className="valori-item-p my-3">
                      {" "}
                      Crediamo in un <b> mercato più equo e sostenibile </b> e
                      per questo motivo orientiamo gli acquisiti online solo
                      verso medi e piccoli eCommerce affidabili e meritevoli,
                      contribuendo così alla loro{" "}
                      <b>
                        {" "}
                        crescita attraverso servizi avanzati ad alto valore
                        aggiunto{" "}
                      </b>{" "}
                      e in linea con le esigenze quotidiane dei nostri clienti.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center my-3">
                <div className="d-flex flex-column align-items-start valori-item mx-2 p-5 h-100">
                  <div className="align-items-stretch">
                    <h3 className="valori-item-title mt-1 mb-4 mx-auto">
                      <img
                        src={valori3}
                        className="d-inline-block img-fluid"
                        width="38"
                        height="38"
                        alt="Qualità"
                        loading="lazy"
                      />
                      Qualità{" "}
                    </h3>
                    <p className="valori-item-p my-3">
                      {" "}
                      Siamo orgogliosi di far conoscere a migliaia di persone
                      anche prodotti e servizi di nicchia, valorizzando le{" "}
                      <b> eccellenze del Made in Italy</b>, spesso difficili da
                      reperire nella grande distribuzione. <br /> Diamo ancora
                      più luce alle gemme del nostro paese, da nord a sud.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center my-3">
                <div className="d-flex flex-column align-items-start valori-item mx-2 p-5 h-100">
                  <div className="align-items-stretch">
                    <h3 className="valori-item-title mt-1 mb-4 mx-auto">
                      <img
                        src={valori4}
                        className="d-inline-block img-fluid"
                        width="38"
                        height="38"
                        alt="Affidabilità"
                        loading="lazy"
                      />
                      Affidabilità{" "}
                    </h3>
                    <p className="valori-item-p my-3">
                      {" "}
                      Il nostro scopo è quello di conquistare ogni giorno la{" "}
                      <b> fiducia dei nostri clienti</b>, attraverso un
                      ecosistema sicuro ed affidabile nel quale ogni recensione
                      letta equivalga ad un feedback reale, in grado di aiutare
                      altre persone ad <b> acquistare serenamente </b> e gli
                      eCommerce a migliorare costantemente il proprio
                      posizionamento sul web.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center my-3">
                <div className="d-flex flex-column align-items-start valori-item mx-2 p-5 h-100">
                  <div className="align-items-stretch">
                    <h3 className="valori-item-title mt-1 mb-4 mx-auto">
                      <img
                        src={valori5}
                        className="d-inline-block img-fluid"
                        width="38"
                        height="38"
                        alt="Crescita"
                        loading="lazy"
                      />
                      Crescita{" "}
                    </h3>
                    <p className="valori-item-p my-3">
                      {" "}
                      La nostra crescita avviene insieme a quella dei medi e
                      piccoli e Commerce che ci impegniamo a{" "}
                      <b> sostenere passo dopo passo</b>, grazie a un ecosistema
                      di <b> servizi in continua evoluzione </b> che include
                      nuove funzionalità pensate per migliorarne costantemente
                      la visibilità, la credibilità e quindi le vendite.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-0">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <span className="page-title"> La nostra storia</span>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="Era il 2013"
                  loading="lazy"
                  width="513"
                  height="375"
                  src={sez0}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title"> Era il 2013 </h2>
                <span className="section-txt">
                  {" "}
                  quando Andrea riesce a realizzare dopo diversi anni, il suo
                  sogno: acquistare una barca a vela! Ecco subito il primo
                  problema: tanti materiali da acquistare e la necessità di
                  risparmiare. I materiali cercati vengono proposti, anche a
                  prezzi interessanti, da una miriade di negozi online mai
                  sentiti prima, ognuno specializzato su un determinato
                  prodotto. Andrea cerca online opinioni sui vari negozi ma
                  quasi mai trova commenti utili, perdendo tanto tempo nella
                  ricerca e rischiando perciò di fare acquisti sbagliati…. ad un
                  certo punto un’idea, chiara come il sole: “ci vorrebbe un
                  TripAdvisor degli acquisti online!” Essendo un frequente
                  utilizzatore del portale del gufo, il parallelo è veloce:
                  serve un portale indipendente che metta a confronto diretto
                  gli e-commerce e le relative esperienze d’acquisto dei
                  consumatori!{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-1 info-section-alt">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-2 text-center">
                <img
                  className="img-fluid info-img"
                  alt="Nell’Agosto del 2015"
                  loading="lazy"
                  width="531"
                  height="375"
                  src={sez2}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title"> Nell’Agosto del 2015 </h2>
                <span className="section-txt">
                  {" "}
                  <p>
                    {" "}
                    ne parla con un amico imprenditore, anche lui di nome
                    Andrea, che rimane colpito dall’idea e insieme parte la
                    nuova avventura!{" "}
                  </p>
                  <p>
                    {" "}
                    Nel <b> Gennaio 2016</b> viene messo in piedi il team e ad
                    Aprile viene costituita la startup innovativa World Advisor
                    Srl per la gestione della piattaforma
                  </p>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-3">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <img
                  className="img-fluid info-img"
                  alt="sez_3_title"
                  loading="lazy"
                  width="929"
                  height="390"
                  src={sez3}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <h2 className="section-title section-title-strong">
                  {" "}
                  Nel Luglio del&nbsp;<b> 2017 </b>&nbsp;viene lanciata la prima
                  versione 1.0 di eShoppingAdvisor, presentata al grande
                  pubblico.{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-4 info-section-alt">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-2 text-center">
                <img
                  className="img-fluid info-img"
                  alt="A Marzo 2019"
                  loading="lazy"
                  width="202"
                  height="244"
                  src={sez4}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title"> A Marzo 2019 </h2>
                <span className="section-txt">
                  {" "}
                  l’Agenzia regionale “Sardegna Ricerche” sceglie di supportare
                  ESA su un progetto di potenziamento dei servizi di circa
                  90.000 Euro.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-5">
          <div className="container">
            <div className="row  d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="la partecipazione e il superamento del programma di accelerazione LuissEnlabs di LVenture Group Spa e la pubblicazione della versione 2.0 e poi della 3.0 di eShoppingAdvisor business e consumer."
                  loading="lazy"
                  width="513"
                  height="320"
                  src={sez5}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h2 className="section-title"> Da Dicembre 2019 </h2>
                <span className="section-txt">
                  {" "}
                  la partecipazione e il superamento del programma di
                  accelerazione LuissEnlabs di LVenture Group Spa e la
                  pubblicazione della versione 2.0 e poi della 3.0 di
                  eShoppingAdvisor business e consumer.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-6 info-section-alt">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-2 text-center">
                <img
                  className="img-fluid info-img"
                  alt="A Marzo 2019"
                  loading="lazy"
                  width="410"
                  height="283"
                  src={sez6}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title"> Nel 2020 </h2>
                <span className="section-txt">
                  {" "}
                  oltre <b> 2500 </b> e-commerce scelgono eShoppingAdvisor per
                  migliorare la propria visibilità e credibilità sul web.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-7">
          <div className="container">
            <div className="row  d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="nasce anche il primo motore di ricerca dedicato ai medi e piccoli ecommerce italiani con 15 Milioni di prodotti al suo interno, venduti da oltre 19.000 eCommerce. "
                  loading="lazy"
                  width="427"
                  height="316"
                  src={sez7}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h2 className="section-title"> A Novembre 2020 </h2>
                <span className="section-txt">
                  {" "}
                  nasce anche il primo motore di ricerca dedicato ai medi e
                  piccoli ecommerce italiani con 15 Milioni di prodotti al suo
                  interno, venduti da oltre 19.000 eCommerce.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-8 info-section-alt">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-2 text-center">
                <img
                  className="img-fluid info-img"
                  alt="A Dicembre 2020"
                  loading="lazy"
                  width="468"
                  height="266"
                  src={sez8}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title"> A Dicembre 2020 </h2>
                <span className="section-txt">
                  {" "}
                  il Fondo Nazionale di Innovazione di CDP Venture Capital SGR,
                  guida con LVenture Group Spa ed altri Angels un round di
                  investimento per il potenziamento del progetto di 500.000
                  Euro, che porta il totale degli investimenti del 2020 su
                  eShoppingAdvisor a 610.000 Euro.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-9">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="Sono circa 8000 gli eCommerce che utilizzano i nostri servizi, grazie ai quali accelerano ulteriormente il proprio business."
                  loading="lazy"
                  width="410"
                  height="283"
                  src={sez9}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h2 className="section-title"> A oggi </h2>
                <span className="section-txt">
                  {" "}
                  Sono circa 8000 gli eCommerce che utilizzano i nostri servizi,
                  grazie ai quali accelerano ulteriormente il proprio business.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-12 info-section-alt mb-0">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <h2 className="section-title section-title-strong">
                  {" "}
                  Il team di eShoppingAdvisor{" "}
                </h2>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-center team-container">
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center team-item">
                <span className="team-img-container">
                  <img
                    className="img-fluid team-img"
                    alt="Andrea Carboni"
                    loading="lazy"
                    width="126"
                    height="126"
                    src={team1}
                  />
                </span>
                <span className="team-name"> Andrea Carboni </span>
                <span className="team-role"> CEO &amp; Co-founder </span>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center team-item">
                <span className="team-img-container">
                  <img
                    className="img-fluid team-img"
                    alt="Andrea Ghiani"
                    loading="lazy"
                    width="126"
                    height="126"
                    src={team2}
                  />
                </span>
                <span className="team-name"> Andrea Ghiani </span>
                <span className="team-role"> CFO &amp; Co-founder </span>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-center team-container">
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center team-item">
                <span className="team-img-container">
                  <img
                    className="img-fluid team-img"
                    alt="Lorenzo Demiri"
                    loading="lazy"
                    width="126"
                    height="126"
                    src={team4}
                  />
                </span>
                <span className="team-name"> Lorenzo Demiri </span>
                <span className="team-role"> R&amp;D </span>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center team-item">
                <span className="team-img-container">
                  <img
                    className="img-fluid team-img"
                    alt="Paolo Angelucci"
                    loading="lazy"
                    width="126"
                    height="126"
                    src={team5}
                  />
                </span>
                <span className="team-name"> Paolo Angelucci </span>
                <span className="team-role"> Senior Advisor </span>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center team-item">
                <span className="team-img-container">
                  <img
                    className="img-fluid team-img"
                    alt="Carlo Pala"
                    loading="lazy"
                    width="126"
                    height="126"
                    src={team6}
                  />
                </span>
                <span className="team-name"> Carlo Pala </span>
                <span className="team-role"> CTO </span>
              </div>
            </div>
          </div>
        </div>
        <div className="_info-section">
          <img
            src={backgroundTeam}
            className="d-block img-fluid w-100"
            width="1280"
            height="566"
            alt="team"
            loading="lazy"
          />
        </div>
        <style>{customCss}</style>
      </section>
    </>
  )
}

export default AboutUs;